import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { PrivacyList, Highlight, IndentedBox } from "./Common";

const PersonalData = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Collecting and Using Your Personal Data
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Title variant="card">
              Types of Data Collected
            </Title>
            <Text mt={3}>
              <Highlight>Personal Data</Highlight>
            </Text>
            <Text>
              While using Our Service, We may ask You to provide Us with certain personally
              identifiable information that can be used to contact or identify You.
              Personally identifiable information may include, but is not limited to:
            </Text>
            <IndentedBox>
              <Text mt={3}>
                <Highlight>Information Collected while Using the Website</Highlight>
              </Text>
              <Text>
                While using Our Website, if You choose to enter the following information in the "Contact Us"
                section of the Website, we collect this information in order to provide the information
                You have requested:
            </Text>
              <PrivacyList>
                <li>Name</li>
                <li>Email Address</li>
                <li>Subject Line Content</li>
                <li>Message Content</li>
              </PrivacyList>
            </IndentedBox>
            <Text>We may use this information to provide features of Our Service.</Text>
            <Title variant="card" mt={4}>
              Use of Your Personal Data
            </Title>
            <Text>
              The Company may use Personal Data for the following purposes:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>To contact You:</Highlight> To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a mobile application's
                push notifications regarding updates or informative communications related to the functionalities,
                products or contracted services, including the security updates, when necessary or reasonable for their implementation.
              </li>
              <li>
                <Highlight>To provide You</Highlight> with news, special offers and general information about other goods,
                services and events which we offer that are similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </li>
              <li><Highlight>To manage Your requests:</Highlight> To attend and manage Your requests to Us.</li>
              <li>
                <Highlight>For business transfers:</Highlight> We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets,
                whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data
                held by Us about our Service users is among the assets transferred.
              </li>
              <li>
                <Highlight>For other purposes:</Highlight> We may use Your information for other purposes, such as data analysis,
                identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve
                our Service, products, services, marketing and your experience.
              </li>
            </PrivacyList>
            <Text mt={3}>
              We may share Your personal information in the following situations:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>For business transfers:</Highlight> We may share or transfer Your personal information in connection with,
                or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a
                portion of Our business to another company.
              </li>
              <li>
                <Highlight>With Your consent:</Highlight> We may disclose Your personal information for any other purpose with Your consent.
              </li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Retention of Your Personal Data
            </Title>
            <Text>
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
              We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example,
              if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
            </Text>
            <Text mt={3}>
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time,
              except when this data is used to strengthen the security or to improve the functionality of Our Service,
              or We are legally obligated to retain this data for longer time periods.
            </Text>
            <Title variant="card" mt={4}>
              Transfer of Your Personal Data
            </Title>
            <Text>
              Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties
              involved in the processing are located. It means that this information may be transferred to — and maintained on —
              computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws
              may differ than those from Your jurisdiction.
            </Text>
            <Text mt={3}>
              Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
            </Text>
            <Text mt={3}>
              The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other personal information.
            </Text>
            <Title variant="card" mt={4}>
              Disclosure of Your Personal Data
            </Title>
            <Text>
              <Highlight>Business Transactions</Highlight>
            </Text>
            <Text>
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
              We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
            </Text>
            <Text mt={3}>
              <Highlight>Law enforcement</Highlight>
            </Text>
            <Text>
              Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law
              or in response to valid requests by public authorities (e.g. a court or a government agency).
            </Text>
            <Text mt={3}>
              <Highlight>Other legal requirements</Highlight>
            </Text>
            <Text>
              The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </Text>
            <PrivacyList>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Security of Your Personal Data
            </Title>
            <Text>
              The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet,
              or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect
              Your Personal Data, We cannot guarantee its absolute security.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default PersonalData;
