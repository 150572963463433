import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Email, Section, Text, Title } from "../../../components/Core";
import { Highlight, IndentedBox, PrivacyList } from "../Common";

const CCPAPrivacy = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              CCPA Privacy
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col>
            <Text>
              This privacy notice section for California residents supplements the information
              contained in Our Privacy Policy and it applies solely to all visitors, users, and
              others who reside in the State of California.
            </Text>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Title variant="card">
              Categories of Personal Information Collected
            </Title>
            <Text>
              We collect information that identifies, relates to, describes, references, is
              capable of being associated with, or could reasonably be linked, directly or
              indirectly, with a particular Consumer or Device. The following is a list of
              categories of personal information which we may collect or may have been
              collected from California residents within the last twelve (12) months.
            </Text>
            <Text mt={3}>
              Please note that the categories and examples provided in the list below are
              those defined in the CCPA. This does not mean that all examples of that category
              of personal information were in fact collected by Us, but reflects our good faith
              belief to the best of our knowledge that some of that information from the applicable
              category may be and may have been collected. For example, certain categories of
              personal information would only be collected if You provided such personal
              information directly to Us.
            </Text>
            <IndentedBox>
              <Text mt={3}>
                <Highlight>Category A: Identifiers.</Highlight>
              </Text>
              <Text>
                Examples: A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address, account name,
              driver's license number, passport number, or other similar identifiers. <br />
              Collected: Yes.
              </Text>
              <Text mt={3}>
                <Highlight>Category B: Personal information categories listed in the
                California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</Highlight>
              </Text>
              <Text>
                Examples: A name, signature, Social Security number, physical characteristics
                or description, address, telephone number, passport number, driver's license
                or state identification card number, insurance policy number, education, employment,
                employment history, bank account number, credit card number, debit card number,
                or any other financial information, medical information, or health insurance information.
                Some personal information included in this category may overlap with other categories. <br />
                Collected: Yes.
              </Text>
              <Text mt={3}>
                <Highlight>Category C: Protected classification characteristics
                under California or federal law.</Highlight>
              </Text>
              <Text>
                Examples: Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical condition, physical
                or mental disability, sex (including gender, gender identity, gender expression,
                pregnancy or childbirth and related medical conditions), sexual orientation,
              veteran or military status, genetic information (including familial genetic information). <br />
              Collected: No.
              </Text>
              <Text mt={3}>
                <Highlight>Category D: Commercial information.</Highlight>
              </Text>
              <Text>
                Examples: Records and history of products or services purchased or considered. <br />
              Collected: No.
              </Text>
              <Text mt={3}>
                <Highlight>Category E: Biometric information.</Highlight>
              </Text>
              <Text>
                Examples: Genetic, physiological, behavioral, and biological characteristics,
                or activity patterns used to extract a template or other identifier or identifying
                information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans,
              keystroke, gait, or other physical patterns, and sleep, health, or exercise data. <br />
              Collected: No.
              </Text>
              <Text mt={3}>
                <Highlight>Category F: Internet or other similar network activity.</Highlight>
              </Text>
              <Text>
                Examples: Interaction with our Service or advertisement. <br />
              Collected: Yes.
              </Text>
              <Text mt={3}>
                <Highlight>Category G: Geolocation data.</Highlight>
              </Text>
              <Text>
                Examples: Approximate physical location. <br />
              Collected: Yes.
               </Text>
              <Text mt={3}>
                <Highlight>Category H: Sensory data.</Highlight>
              </Text>
              <Text>
                Examples: Audio, electronic, visual, thermal, olfactory, or similar information. <br />
              Collected: No.
              </Text>
              <Text mt={3}>
                <Highlight>Category I: Professional or employment-related information.</Highlight>
              </Text>
              <Text>
                Examples: Current or past job history or performance evaluations. <br />
              Collected: No.
             </Text>
              <Text mt={3}>
                <Highlight>Category J: Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</Highlight>
              </Text>
              <Text>
                Examples: Education records directly related to a student maintained by an educational
                institution or party acting on its behalf, such as grades, transcripts, class lists,
                student schedules, student identification codes, student financial information,
              or student disciplinary records. <br />
              Collected: No.
              </Text>
              <Text mt={3}>
                <Highlight>Category K: Inferences drawn from other personal information.</Highlight>
              </Text>
              <Text>
                Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
              predispositions, behavior, attitudes, intelligence, abilities, and aptitudes. <br />
              Collected: No.
              </Text>
            </IndentedBox>
            <Text mt={3}>
              Under CCPA, personal information does not include:
            </Text>
            <PrivacyList>
              <li>Publicly available information from government records</li>
              <li>Deidentified or aggregated consumer information</li>
              <li>Information excluded from the CCPA's scope, such as:</li>
              <IndentedBox>
                <li>
                  Health or medical information covered by the Health Insurance Portability and
                  Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                  Information Act (CMIA) or clinical trial data
                </li>
                <li>
                  Personal Information covered by certain sector-specific privacy laws, including
                  the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                  Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994
                </li>
              </IndentedBox>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Sources of Personal Information
            </Title>
            <Text>
              We obtain the categories of personal information listed above from the following categories of sources:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>Directly from You.</Highlight> For example, from the forms You complete on our Service,
                preferences You express or provide through our Service.
              </li>
              <li>
                <Highlight>From Service Providers </Highlight> For example, or other third-party vendors that
                We use to provide the Service to You.
              </li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Use of Personal Information for Business Purposes or Commercial Purposes
            </Title>
            <Text>
              We may use or disclose personal information We collect for "business purposes" or
              "commercial purposes" (as defined under the CCPA), which may include the following examples:
            </Text>
            <PrivacyList>
              <li>
                To operate our Service and provide You with our Service.
              </li>
              <li>
                To provide You with support and to respond to Your inquiries, including to investigate
                and address Your concerns and monitor and improve our Service.
              </li>
              <li>
                To fulfill or meet the reason You provided the information. For example, if You share
                Your contact information to ask a question about our Service, We will use that personal
                information to respond to Your inquiry.
              </li>
              <li>
                To respond to law enforcement requests and as required by applicable law, court order,
                or governmental regulations.
              </li>
              <li>
                As described to You when collecting Your personal information or as otherwise set
                forth in the CCPA.
              </li>
              <li>
                For internal administrative and auditing purposes.
              </li>
              <li>
                To detect security incidents and protect against malicious, deceptive, fraudulent or
                illegal activity, including, when necessary, to prosecute those responsible for such activities.
              </li>
            </PrivacyList>
            <Text mt={3}>
              Please note that the examples provided above are illustrative and not intended to be exhaustive.
              For more details on how we use this information, please refer to the "Use of Your Personal Data" section.
            </Text>
            <Text mt={3}>
              If We decide to collect additional categories of personal information or use the personal
              information We collected for materially different, unrelated, or incompatible purposes
              We will update this Privacy Policy.
            </Text>
            <Title variant="card" mt={4}>
              Disclosure of Personal Information for Business Purposes or Commercial Purposes
            </Title>
            <Text>
              We may use or disclose the following categories of personal information for business
              or commercial purposes:
            </Text>
            <PrivacyList>
              <li>Category A: Identifiers</li>
              <li>Category B: Personal information categories listed in the California Customer Records statute
                (Cal. Civ. Code § 1798.80(e))</li>
              <li>Category F: Internet or other similar network activity</li>
              <li>Category G: Geolocation data</li>
            </PrivacyList>
            <Text>
              Please note that the categories listed above are those defined in the CCPA. This does not mean
              that all examples of that category of personal information were in fact disclosed, but reflects
              our good faith belief to the best of our knowledge that some of that information from the
              applicable category may be and may have been disclosed.
            </Text>
            <Text mt={3}>
              When We disclose personal information for a business purpose or a commercial purpose, We enter
              a contract that describes the purpose and requires the recipient to both keep that personal
              information confidential and not use it for any purpose except performing the contract.
            </Text>
            <Title variant="card" mt={4}>
              Sale of Personal Information
            </Title>
            <Text>
              As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing,
              disseminating, making available, transferring, or otherwise communicating orally,
              in writing, or by electronic or other means, a consumer's personal information by the
              business to a third-party for valuable consideration. This means that We may have received
              some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.
            </Text>
            <Text mt={3}>
              Please note that the categories listed below are those defined in the CCPA. This does not mean
              that all examples of that category of personal information were in fact sold, but reflects our
              good faith belief to the best of our knowledge that some of that information from the applicable
              category may be shared for value in return in the future.
            </Text>
            <Text mt={3}>
              We may sell the following categories of personal information:
            </Text>
            <PrivacyList>
              <li>Category A: Identifiers</li>
              <li>Category B: Personal information categories listed in the California Customer Records statute
                (Cal. Civ. Code § 1798.80(e))</li>
              <li>Category F: Internet or other similar network activity</li>
              <li>Category G: Geolocation data</li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Share of Personal Information
            </Title>
            <Text>
              We may share Your personal information identified in the above categories with the following
              categories of third parties:
            </Text>
            <PrivacyList>
              <li><Highlight>Service Providers:</Highlight> Third party vendors to whom You or Your agents
                authorize Us to disclose Your personal information in connection with products or
                services We provide to You</li>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Sale of Personal Information of Minors Under 16 Years of Age
            </Title>
            <Text>
              We do not sell the personal information of Consumers We actually know are less than 16
              years of age, unless We receive affirmative authorization (the "right to opt-in") from
              either the Consumer who is between 13 and 16 years of age, or the parent or guardian of
              a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal
              information may opt-out of future sales at any time. To exercise the right to opt-out,
              You (or Your authorized representative) may submit a request to Us by contacting Us.
            </Text>
            <Text mt={3}>
              If You have reason to believe that a child under the age of 13 (or 16) has provided Us
              with personal information, please contact Us with sufficient detail to enable Us to
              delete that information.
            </Text>
            <Title variant="card" mt={4}>
              Your Rights under the CCPA
            </Title>
            <Text>
              The CCPA provides California residents with specific rights regarding their personal
              information. If You are a resident of California, You have the following rights:
            </Text>
            <PrivacyList>
              <li>
                <Highlight>The right to notice.</Highlight> You have the right to be notified
                which categories of Personal Data are being collected and the purposes for
                which the Personal Data is being used.
              </li>
              <li>
                <Highlight>The right to request.</Highlight> Under CCPA, You have the right
                to request that We disclose information to You about Our collection, use,
                sale, disclosure for business purposes and share of personal information.
                Once We receive and confirm Your request, We will disclose to You:
              </li>
              <IndentedBox>
                <li>The categories of personal information We collected about You</li>
                <li>The categories of sources for the personal information We collected about You</li>
                <li>Our business or commercial purpose for collecting or selling that personal information</li>
                <li>The categories of third parties with whom We share that personal information</li>
                <li>The specific pieces of personal information We collected about You</li>
                <li>
                  If we sold Your personal information or disclosed Your personal information for a
                  business purpose, We will disclose to You:
                </li>
                <IndentedBox>
                  <li>The categories of personal information categories sold</li>
                  <li>The categories of personal information categories disclosed</li>
                </IndentedBox>
              </IndentedBox>
              <li>
                <Highlight>The right to say no to the sale of Personal Data (opt-out).</Highlight> You have
                the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us.
              </li>
              <li>
                <Highlight>The right to delete Personal Data.</Highlight> You have the right
                to request the deletion of Your Personal Data, subject to certain exceptions.
                Once We receive and confirm Your request, We will delete (and direct Our Service
                Providers to delete) Your personal information from our records, unless an exception applies.
                We may deny Your deletion request if retaining the information
                is necessary for Us or Our Service Providers to:
              </li>
              <IndentedBox>
                <li>
                  Complete the transaction for which We collected the personal information,
                  provide a good or service that You requested, take actions reasonably anticipated
                  within the context of our ongoing business relationship with You, or otherwise
                  perform our contract with You.
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent,
                  or illegal activity, or prosecute those responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to exercise their free speech
                  rights, or exercise another right provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or statistical research in the
                  public interest that adheres to all other applicable ethics and privacy laws, when the
                  information's deletion may likely render impossible or seriously impair the research's
                  achievement, if You previously provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with consumer expectations
                  based on Your relationship with Us.
                </li>
                <li>
                  Comply with a legal obligation.
                </li>
                <li>
                  Make other internal and lawful uses of that information that are compatible with the context in which You provided it.
                </li>
              </IndentedBox>
              <li>
                <Highlight>The right not to be discriminated against.</Highlight> You have the right not to be discriminated
                against for exercising any of Your consumer's rights, including by:
              </li>
              <IndentedBox>
                <li>
                  Denying goods or services to You
                </li>
                <li>
                  Charging different prices or rates for goods or services, including the use of discounts or other
                  benefits or imposing penalties
                </li>
                <li>
                  Providing a different level or quality of goods or services to You
                </li>
                <li>
                  Suggesting that You will receive a different price or rate for goods or services or a
                  different level or quality of goods or services
                </li>
              </IndentedBox>
            </PrivacyList>
            <Title variant="card" mt={4}>
              Exercising Your CCPA Data Protection Rights
            </Title>
            <Text>
              In order to exercise any of Your rights under the CCPA, and if You are a California resident,
              You can contact Us:
            </Text>
            <PrivacyList>
              <li><Highlight>By email:</Highlight> <Email address="privacy@sharptree.io" /></li>
            </PrivacyList>
            <Text>
              Only You, or a person registered with the California Secretary of State that You authorize
              to act on Your behalf, may make a verifiable request related to Your personal information.
            </Text>
            <Text mt={3}>
              Your request to Us must:
            </Text>
            <PrivacyList>
              <li>
                Provide sufficient information that allows Us to reasonably verify You are the person
                about whom We collected personal information or an authorized representative
              </li>
              <li>
                Describe Your request with sufficient detail that allows Us to properly understand,
                evaluate, and respond to it
              </li>
            </PrivacyList>
            <Text>
              We cannot respond to Your request or provide You with the required information if We cannot:
            </Text>
            <PrivacyList>
              <li>
                Verify Your identity or authority to make the request
              </li>
              <li>
                And confirm that the personal information relates to You
              </li>
            </PrivacyList>
            <Text>
              We will disclose and deliver the required information free of charge within 45 days of
              receiving Your verifiable request. The time period to provide the required information
              may be extended once by an additional 45 days when reasonable necessary and with prior notice.
            </Text>
            <Text mt={3}>
              Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.
            </Text>
            <Text mt={3}>
              For data portability requests, We will select a format to provide Your personal information
              that is readily useable and should allow You to transmit the information from one entity to
              another entity without hindrance.
            </Text>
            <Title variant="card" mt={4}>
              Do Not Sell My Personal Information
            </Title>
            <Text>
              You have the right to opt-out of the sale of Your personal information. Once We receive and
              confirm a verifiable consumer request from You, we will not sell Your personal information.
              To exercise Your right to opt-out, please contact Us.
            </Text>
            <Text mt={3}>
              For further details on the information We might collect, You can refer to the
              "Types of Data Collected" section of this Privacy Policy. We follow our standard Privacy
              Policy for the disclosure of personal information collected from and about children.
            </Text>
            <Title variant="card" mt={4}>
              Parental Access
            </Title>
            <Text>
              A parent who has already given the Company permission to collect and use his child
              personal information can, at any time:
            </Text>
            <PrivacyList>
              <li>
                Review, correct or delete the child's personal information
              </li>
              <li>
                Discontinue further collection or use of the child's personal information
              </li>
            </PrivacyList>
            <Text>
              To make such a request, You can write to Us using the contact information provided in this Privacy Policy.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CCPAPrivacy;
