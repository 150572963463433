import React from "react";
import { Section } from "../components/Core";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import CCPAPrivacy from "../sections/privacy/ccpa/CCPAPrivacy";
import CCPARights from "../sections/privacy/ccpa/CCPARights";
import CCPADefinitions from "../sections/privacy/ccpa/Definitions";
import CCPAIntro from "../sections/privacy/ccpa/Intro";
import CCPAPersonalData from "../sections/privacy/ccpa/PersonalData";
import ContactUs from "../sections/privacy/ContactUs";
import Definitions from "../sections/privacy/Definitions";
import GDPR from "../sections/privacy/GDPR";
import Intro from "../sections/privacy/Intro";
import OtherWebsiteLinks from "../sections/privacy/OtherWebsiteLinks";
import PersonalData from "../sections/privacy/PersonalData";
import PolicyChanges from "../sections/privacy/PolicyChanges";

const Privacy = (props) => {
  return (
    <>
      <PageWrapper>
        <Seo 
          title="Sharptree | Privacy | Mobile Reimagined"
          description="We respect your privacy and do not track you in any way on our site."
          pathname={props.location.pathname} 
        />
        <PageHero title="Privacy" />
        <Intro />
        <Definitions />
        <PersonalData />
        <GDPR />
        <OtherWebsiteLinks />
        <PolicyChanges />
        <ContactUs />

        <Section bg="greyBackground" py={0}>
          <CCPAIntro />
          <CCPADefinitions />
          <CCPAPersonalData />
          <CCPAPrivacy />
          <CCPARights />
          <OtherWebsiteLinks />
          <PolicyChanges />
          <ContactUs />
        </Section>
      </PageWrapper>
    </>
  );
};
export default Privacy;
